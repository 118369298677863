import React from 'react'
export type FooterProps = any

export const Footer = (props: FooterProps) => {
    return (
        <footer className="p-3">
            <div className="container text-center">
                <div className="text-center small text-muted">
                    © {new Date().getFullYear()} RightCandidate.AI - Angular Minds, All rights reserved.
                </div>
            </div>
        </footer>
    )
}
