import React from 'react'
import { Header } from '../../components/layout/header/Header'
import { Router, Switch } from 'react-router-dom'
import { Footer } from '../../components/layout/footer/Footer'
import history from '../../routes/history'
import Routes from '../../routes/routes'
import { Row, Container } from 'react-bootstrap'
import UserProvider from '../../context/UserContext'
import InterviewerHeader from '../../pages/interviewer-dashboard/interviewerHeader'

export default function InterviewerLayout(props) {
    return (
        <div className="mh-100">
            <UserProvider>
                <InterviewerHeader />
                <Container fluid className="mh-100 pt-3 pb-2">
                    <Row>
                        <Router history={history}>
                            <Switch>
                                <Routes
                                    exact
                                    component={props.item.component}
                                    path={props.item.path}
                                />
                            </Switch>
                        </Router>
                    </Row>
                </Container>
                <Footer />
            </UserProvider>
        </div>
    )
}
