import { Subject } from 'rxjs';

const subject = new Subject();

export const messageService = {
    sendMessage: message => subject.next(message),
    getMessage: () => subject.asObservable()
};

/*
 * Custom methods for showing snackbars
 */

export function showErrorToast(message: string) {
    messageService.sendMessage({
        message: message,
        severity: 'error'
    });
}

export function showSuccessToast(message: string) {
    messageService.sendMessage({
        message: message,
        severity: 'success'
    });
}

export function showWarningToast(message: string) {
    messageService.sendMessage({
        message: message,
        severity: 'warning'
    });
}

export function showInfoToast(message: string) {
    messageService.sendMessage({
        message: message,
        severity: 'info'
    });
}
