import React from 'react';
import { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
//create a context, with createContext api
export const UserContext = createContext<any>('');

const UserProvider = (props) => {
    // this state will be shared with all components
    let location=useLocation()
    const [user, setUser] = useState<({ [key: string]: any })>(()=>location.pathname.includes('/interviewer')?JSON.parse(localStorage.getItem('currentInterviewer') || '{}'):JSON.parse(localStorage.getItem('currentUser') || '{}'));
 return (  
        // this is the provider providing state
        <UserContext.Provider value={{ user, setUser }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;