import { lazy, LazyExoticComponent } from 'react'

// Lazy load your page components
const Login = lazy(() => import('../pages/auth/login/Login'))
const Register = lazy(() => import('../pages/auth/register/register'))
const ActivateUser = lazy(() => import('../pages/auth/activate-user/activate-user'))
const AcceptInvite = lazy(() => import('../pages/auth/accept-invite/accept-invite'))
const ForgotPassword = lazy(
    () => import('../pages/auth/forgot-password/forgot-password')
)
const ChangePassword = lazy(
    () => import('../pages/auth/change-password/change-password')
)
const PasswordStrength = lazy(
    () => import('../pages/password-strength/password-strength')
)
const SetPassword = lazy(() => import('../pages/auth/set-password/set-password'))
const SecondFactor = lazy(() => import('../pages/auth/second-factor/second-factor'))
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))
const VerifyEmail = lazy(() => import('../pages/email-verification/index'))
const UserList = lazy(() => import('../pages/users/Users'))
const UserProfile = lazy(() => import('../pages/user-profile/UserProfile'))
const JobList = lazy(() => import('../pages/display-job-list/JobList'))
const CreateJob = lazy(() => import('../pages/create-and-edit-job/jobs/Jobs'))
const EditJob = lazy(() => import('../pages/create-and-edit-job/jobs/Jobs'))
const Candidates = lazy(() => import('../components/candidates/candidate-list/candidates-list'))
const AddCandidate = lazy(() => import('../components/candidates/Add-Candidate/AddCandidate'))
const InterviewerLogin = lazy(() => import('../pages/auth/interviewer-module/LoginInterviewer'))
const CandidateDashboard = lazy(() => import('../components/candidates/candidate-dashboard/CandidateDashboard'))
const Settings = lazy(() => import('../components/settings/Settings'))
const InterviewerDashboard = lazy(() => import('../pages/interviewer-dashboard/InterviewerDashboard'))
const PastSchedules = lazy(() => import('../pages/interviewer-dashboard/PastSchedules'))
const OverviewOfEvent = lazy(() => import('../pages/interviewer-dashboard/OverviewPage'))
const TalentPool = lazy(() => import('../pages/talent-pool/TalentPool'))
const CandidateDashboardList = lazy(() => import('../pages/dashboard/display-candidate-list/CandidateDashboardList'))
const FeedbackNotProvided = lazy(() => import('../pages/interviewer-dashboard/FeedbackNotProvided'))
const ScheduleList = lazy(() => import('../pages/agenda/ScheduleList'))
const interviewerOrganization = lazy(() => import('../pages/interviewer-dashboard/InterviewerOrganiztion/InterviewerOrganization'))
/*
 * Route path: URLs
 */
export const paths = {
    defaultPostLogin: '/dashboard',
    dashboard: '/dashboard',
    login: '/auth/login',
    register: '/auth/register',
    activateUser: '/auth/activate-user',
    acceptInvite: '/auth/accept-invite',
    forgotPassword: '/auth/forgot-password',
    changePassword: '/auth/change-password',
    passwordStrength: '/auth/password-strength',
    setPassword: '/auth/reset-password',
    secondFactor: '/auth/second-factor',
    verifyEmail: `/auth/verify-email`,
    userList: '/user-list',
    organization: '/settings/organization',
    masters: '/settings/masters/:id',
    feedbackForm: '/settings/feedback-forms',
    editFeedbackForm: '/settings/feedback-form/:id',
    userProfile: '/user-profile',
    workflows: '/settings/workflows',
    workflowStages: '/settings/workflow-stages/:id',
    jobList: '/jobs',
    createJob: '/jobs/create-job',
    editJob: '/settings/jobs/edit-Job/:id',
    mastersType: '/masters/:type',
    settings: '/settings',
    candidates: '/candidates',
    addCandidate: '/add-candidate',
    candidateDashboard: '/candidate-dashboard/:id',
    interviewerLogin: '/interviewer-auth',
    editCandidate: `/edit-candidate/:id`,
    candidateFeedback: `/candidate/feedback/:id`,
    interviewerDashboard: '/interviewer/dashboard',
    pastSchedules: '/interviewer/past-schedules',
    feedbackNotProvided: '/interviewer/feedback-not-provided',
    overviewOfEvent: '/interviewer/overview-page/:id',
    jobs: '/settings/jobs',
    eventLogs: '/event-logs',
    candidate: '/candidates/:id',
    talentPool: '/talent-pool',
    editDashboardJobs: '/jobs/edit-job/:id',
    candidateDashboardList: '/jobs/:id',
    agenda: '/agenda',
    overviewOfAgenda: '/agenda/overview-page/:id',
    interviewerUserProfile: '/interviewer/user-profile',
    interviewerOrganization: '/interviewer/settings'
}

/*
 * Components
 */
export const components = {
    Permissions,
}

/*
 * Type defined for path component
 */
interface PathComponent {
    path: string
    component: LazyExoticComponent<(props: any) => JSX.Element>
}

/*
 * Routes: path & lazy loaded component
 */

export const routes: PathComponent[] = [
    {
        path: paths.login,
        component: Login,
    },
    {
        path: paths.register,
        component: Register,
    },
    {
        path: paths.activateUser,
        component: ActivateUser,
    },
    {
        path: paths.acceptInvite,
        component: AcceptInvite,
    },
    {
        path: paths.forgotPassword,
        component: ForgotPassword,
    },
    {
        path: paths.changePassword,
        component: ChangePassword,
    },
    {
        path: paths.passwordStrength,
        component: PasswordStrength,
    },
    {
        path: paths.setPassword,
        component: SetPassword,
    },
    {
        path: paths.secondFactor,
        component: SecondFactor,
    },
    {
        path: paths.dashboard,
        component: Dashboard,
    },
    {
        path: paths.userProfile,
        component: UserProfile,
    },
    {
        path: paths.verifyEmail,
        component: VerifyEmail,
    },
    {
        path: paths.masters,
        component: Settings,
    },
    {
        path: paths.userList,
        component: UserList,
    },
    {
        path: paths.organization,
        component: Settings,
    },
    {
        path: paths.settings,
        component: Settings,
    },
    {
        path: paths.workflows,
        component: Settings,
    },
    {
        path: paths.workflowStages,
        component: Settings,
    },
    {
        path: paths.eventLogs,
        component: Settings,
    },
    {
        path: paths.jobList,
        component: JobList,
    },
    {
        path: paths.createJob,
        component: CreateJob,
    },
    {
        path: paths.editJob,
        component: EditJob,
    },
    {
        path: paths.feedbackForm,
        component: Settings,
    },
    {
        path: paths.editFeedbackForm,
        component: Settings,
    },
    {
        path: paths.candidates,
        component: Candidates,
    },
    {
        path: paths.addCandidate,
        component: AddCandidate,
    },
    {
        path: paths.candidateDashboard,
        component: CandidateDashboard,
    },
    {
        path: paths.editCandidate,
        component: AddCandidate,
    },
    {
        path: paths.interviewerLogin,
        component: InterviewerLogin,
    },
    {
        path: paths.jobs,
        component: Settings,
    },
    {
        path: paths.candidate,
        component: Candidates,
    },
    {
        path: paths.talentPool,
        component: TalentPool,
    },
    {
        path: paths.editDashboardJobs,
        component: EditJob,
    },
    {
        path: paths.candidateDashboardList,
        component: CandidateDashboardList,
    },
    {
        path: paths.agenda,
        component: ScheduleList,
    },
]

export const interviewerRoutes: PathComponent[] = [
    {
        path: paths.interviewerLogin,
        component: InterviewerLogin,
    },
    {
        path: paths.interviewerDashboard,
        component: InterviewerDashboard,
    },
    {
        path: paths.pastSchedules,
        component: PastSchedules,
    },
    {
        path: paths.feedbackNotProvided,
        component: FeedbackNotProvided,
    },
    {
        path: paths.overviewOfEvent,
        component: OverviewOfEvent,
    },
    {
        path: paths.login,
        component: Login,
    },

    {
        path: paths.interviewerUserProfile,
        component: UserProfile,
    },
    {
        path: paths.interviewerOrganization,
        component: interviewerOrganization
    }
]
