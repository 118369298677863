import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { messageService } from './toastUtil';


function CustomSnackbar() {

    const emptySnack = {
        message: '',
        open: false,
        severity: 'info' as AlertColor
    } as any;

    const [snack, setSnack] = useState(emptySnack);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(()=>{
        let componentMounted = true;
        messageService.getMessage().subscribe({
            next: (message: any) => {
                if(componentMounted) {
                setSnack({ open: true, ...message });
                }}
        });
        return () => {
            componentMounted = false;
        }
    },[])

    return (
        <Snackbar open={snack.open} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setSnack(emptySnack)}>
            <Alert severity={snack.severity} sx={{ width: '100%' }}>
                {snack.message}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar