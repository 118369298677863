import React, { useContext } from 'react'
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { authenticationService } from '../../../services/auth.service'
import history from '../../../routes/history'
import './Header.scss'
import logo from '../../../assets/logo-whithout-tagline_white 1.png'
import { Person, MenuButtonWide } from 'react-bootstrap-icons'
import { Gear } from 'react-bootstrap-icons'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { FaRegBell } from 'react-icons/fa'
import { paths } from '../../../routes/routes.config'
import { UserContext } from '../../../context/UserContext'
import { NavLink } from 'react-router-dom'
import AvatarImg from './AvatarImg'
import { BsCardChecklist } from 'react-icons/bs'
import { TbLayoutDashboard, TbUsers } from 'react-icons/tb'
import { MdFolderShared, MdOutlineFolderShared } from 'react-icons/md'
import talentpool from '../../../assets/talentpool.svg'
export const Header = (props: any) => {
    const location = useLocation()
    const { user } = useContext(UserContext)

    //user profile
    const handleUserProfile = () => {
        history.push(paths.userProfile)
    }

    //dropdown toggle
    type CustomToggleProps = {
        children?: React.ReactNode
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
    }

    const CustomToggle = React.forwardRef(
        (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
            <Nav.Link
                as={NavLink}
                to="#!"
                ref={ref}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.onClick) props.onClick(e)
                }}
            >
                <AvatarImg
                    className={'avatar'}
                    name={user?.name}
                    profilePicture={`${user?.profilePicture
                        ?.link}?${Date.now()}`}
                    round={true}
                    size="30px"
                />
            </Nav.Link>
        )
    )
    function handleLogout() {
        authenticationService.logout()
    }
    return (
        <>
            <Navbar variant="dark" fixed="top" className="navbar-style py-0">
                <Container fluid={true} className="h-100 Header">
                    <Navbar.Brand href="/" className="navbar-logo">
                        <img src={logo} alt="" height={30} className="mr-4" />
                    </Navbar.Brand>

                    <Nav className="h-100" activeKey={location.pathname}>
                        <Nav.Item className="h-100 mx-3">
                            <Nav.Link
                                as={NavLink}
                                className={`h-100 px-2 d-flex align-items-center  ${location.pathname === '/dashboard'
                                    ? 'active'
                                    : ''
                                    }`}
                                to="/dashboard"
                            >
                                <TbLayoutDashboard className="icon mr-1 pr-1" />
                                Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="h-100 mx-3">
                            <Nav.Link
                                as={NavLink}
                                className={`h-100 px-2 d-flex align-items-center  ${location.pathname === '/candidates'
                                    ? 'active'
                                    : ''
                                    }`}
                                to="/candidates"
                            >
                                <TbUsers className="icon mr-1 pr-1" />
                                Candidates
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="h-100 mx-3">
                            <Nav.Link
                                as={NavLink}
                                className={`h-100 px-2 d-flex align-items-center  ${location.pathname === '/talent-pool'
                                    ? 'active'
                                    : ''
                                    }`}
                                to="/talent-pool"
                            >
                                <MdOutlineFolderShared className="icon mr-1 pr-1" />
                                Talent Pool
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="h-100 mx-3">
                            <Nav.Link
                                as={NavLink}
                                className={`h-100 px-2 d-flex align-items-center  ${location.pathname === '/agenda' ? 'active' : ''
                                    }`}
                                to="/agenda"
                            >
                                <BsCardChecklist className="icon mr-1 pr-1" />
                                Agenda
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav className="ml-auto ">
                        <div className="d-flex align-items-center justify-content-around">
                            <li className="mr-3">
                                <FaRegBell className="notification-icon-style" />
                            </li>
                            <li>
                                <Dropdown className="mr-3 mt-1">
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-custom-components"
                                    ></Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2 float-right dropdown-left-manual">
                                        <p
                                            className="ml-3 mb-2"
                                            style={{ color: '#000000' }}
                                        >
                                            Hello, {user?.name}
                                        </p>
                                        <hr className='my-0 mx-2' />
                                        <Dropdown.Item onClick={handleUserProfile}>
                                            <Person /><span className='pl-2'>My Profile</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => history.push('/settings')}>
                                            <Gear /><span className='pl-2'>Organization Settings</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#/logout"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleLogout()
                                            }}
                                        >
                                            <BoxArrowRight /><span className='pl-2'>Logout</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </div>
                    </Nav>
                </Container>
            </Navbar>
            <Navbar className="navbar-style py-0 opacity-0">
                {/* Fake transparent moving navbar for spacing */}
                <Container className="h-100 Header"></Container>
            </Navbar>
        </>
    )
}
