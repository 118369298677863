import React, { Suspense, createContext } from 'react'
import { AppLoader } from '../components/app-loader/AppLoader'
import { AppNavigator } from '../components/app-navigator'
import CustomSnackbar from '../utils/CustomSnackbar'

export const SnackbarContext = createContext({});

export default function App() {
    /*
     * Render
     */
    return (
        <Suspense fallback={<AppLoader />}>
            <CustomSnackbar />
            <AppNavigator />
        </Suspense>
    )
}