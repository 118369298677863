import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { interviewerRoutes } from '../../routes/routes.config'
import history from '../../routes/history'
import UserProvider from '../../context/UserContext'
import { recaptchaKey } from '../../services/constants/keys'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Footer } from '../../components/layout/footer'
import AMLogo from '../../assets/AMLogo.svg'
export default function interviewerAuthLayout(props) {
    return (
        <div className="overflow-auto">
            <UserProvider>
                <div className="text-center pt-4 mt-4">
                    <img src={AMLogo} alt="logo" style={{ height: '5rem' }} />

                    <h3
                        style={{
                            color: '#00213E',
                            marginTop:'1rem',
                            fontWeight: 600,
                        }}
                    >
                        RightCandidate.AI
                    </h3>
                </div>
                <div className="container">
                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                        <Router history={history}>
                            <Switch>
                                {interviewerRoutes.map((item) => (
                                    <Route exact key={item.path} path={item.path}>
                                        <item.component />
                                    </Route>
                                ))}
                            </Switch>
                        </Router>
                    </GoogleReCaptchaProvider>
                    <Footer />
                </div>
            </UserProvider>
        </div>
    )
}
