import { BehaviorSubject } from 'rxjs'
import { get, post, put, patch, del } from './http/httpMethods'
import Cookie from 'js-cookie'
import history from '../routes/history'
import { paths } from '../routes/routes.config'
import { showErrorToast } from '../utils/toastUtil'
import { interviewerService } from './interviewer.service'

let currentUserFromStorage: any

/*
 * Get current user from local storage
 */
try {
    currentUserFromStorage = localStorage.getItem('currentUser')
    currentUserFromStorage = JSON.parse(currentUserFromStorage)
    if (currentUserFromStorage) {
        loadCurrentUser()
    }
    else if (localStorage.getItem('currentInterviewer')) {
        interviewerService.loadCurrentUser() 
    }
} catch (e) {
    logout()
    interviewerService.logout()
}

const currentUserSubject = new BehaviorSubject(currentUserFromStorage || undefined)
const currentOrganizationSubject = new BehaviorSubject(
    (currentUserFromStorage &&
        currentUserFromStorage._org &&
        currentUserFromStorage._org[0]) ||
    undefined
)

/*
 * Export as a Type
 */
export const authenticationService = {
    logout,
    authToken,
    register,
    verifyCredentials,
    activateUser,
    verifyInvitation,
    acceptInvitation,
    loadCurrentUser,
    requestPasswordReset,
    setPassword,
    requestPasswordChange,
    isUserAndTokenAvailable,
    verifyOTP,
    handleLogin,
    localLogout,
    resendOTP,
    unsubscribeAll,
    changeEmail,
    sendVerificationEmail,
    sendVerificationEmailv1,
    verifyEmail,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentOrganization: currentOrganizationSubject.asObservable(),
    get currentOrganizationValue() {
        return currentOrganizationSubject.value
    },
}

/*
 * Verify OTP method
 */
function verifyCredentials(payload: any) {
    console.log('register', payload)
    return post('/auth/login', payload)
        .then((response: any) => {
            console.log(response)
            if (!response.tfa) {
                handleLogin(response)
            }
            return response
        })
}

/*
 * Verify OTP method
 */
function requestPasswordReset(payload: any) {
    console.log('register', payload)
    return post('/auth/forgot-password', payload).then((response: any) => {
        console.log(response)
        return response
    })
}

/*
 * Change password
 */

function requestPasswordChange(payload: any) {
    console.log('payload......', payload)
    return put('/users/change-password', payload).then((response: any) => {
        console.log('response54546.......', response)
        return response
    })
}
/*
 * Unsubscribe all subjects to avoid memory leak
 */
function unsubscribeAll() {
    currentUserSubject.unsubscribe()
    currentOrganizationSubject.unsubscribe()
}

/*
 * Logout method
 */
function logout() {
    localStorage.removeItem('currentUser')
    Cookie.remove('_token', { path: '/' })
    currentUserSubject.next({})
    window.location.href = '/'
}

/*
 * Local logout, don't send API call
 */
function localLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')

    Cookie.remove('_token', { path: '/' })

    currentUserSubject.next({})

    if (window.location.pathname !== '/auth/login') {
        window.location.href = '/auth/login'
    }
}

/*
 * Get auth token from cookie
 */
function authToken() {
    return Cookie.get('_token')
}

/*
 * Register user method
 */
function register(payload: any) {
    console.log('register', payload)
    return post('/auth/register', {
        name: payload.name,
        email: payload.email,
        password: payload.password,
        company: payload.companyName,
        captcha: payload.captcha,
    }).then(
        (response: any) => {
            Cookie.set('_token', response.token, { path: '/' })
            sendVerificationEmail()
            return response
        }
    )
}

/*
 * Accept onboarding invitation
 */
function acceptInvitation(payload: any, token: string) {
    return post('/api/invite-users/accept', payload, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        // handleLogin(response)
        return response
    })
}

/*
 * Set new password
 */
function setPassword(payload: any, token: string) {
    return post(`/auth/reset-password?token=${token}`, payload).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Verify OTP
 */
function verifyOTP(payload: any) {
    return post('/auth/verify-tfa', payload).then((response: any) => {
        return response
    })
}

/*
 * Verify OTP
 */
function resendOTP() {
    return get('/api/auth/regenerate-second-factor').then((response: any) => {
        handleLogin(response)
        return response
    })
}

/*
 * Activate user
 */
function activateUser(token: string, activate: boolean = false) {
    return get(`/api/user/self${activate ? `?activate=${activate}` : ``}`, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        return response
    })
}

/*
 * Verify invitation
 */
function verifyInvitation(token: string) {
    return get(`/api/invite-users/self`, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        return response
    })
}

/*
 * Verify invitation
 */
function isUserAndTokenAvailable() {
    return authToken() && JSON.parse(localStorage.getItem('currentUser') as any)
}

/*
 * Fetch current user
 */
function loadCurrentUser() {
    return get(`/auth/self`).then((response: any) => {
        localStorage.setItem('currentUser', JSON.stringify(response))

        currentUserSubject.next(response)
        currentOrganizationSubject.next(response._org[0])

        return response
    })
}


/*
 * Register user method
 */
function handleLogin(response: any) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    Cookie.set('_token', response.token, { path: '/' })

    localStorage.setItem('currentUser', JSON.stringify(response.user))

    currentUserSubject.next(response.user)

    currentOrganizationSubject.next(response.user._org[0])

    if (response.user && !response.user._pre) {
        history.push(paths.defaultPostLogin)
    }
}

/*
 * Change Email Address
 */
function changeEmail(payload: any) {
    return patch('/auth/update-email', payload)
        .then((response: any) => {
            return response
        })
        .catch((err) => err)
}

/*
 * Send Verification Email
 */
function sendVerificationEmail() {
    return post(`/auth/send-verification-email`).then((response: any) => {
        return response
    })
}

function sendVerificationEmailv1(payload:any) {
    console.log(payload)
    return post(`/auth/send-verification-email/v1`,payload).then((response: any) => {
        return response
    })
}
/*
 * Verify Email
 */
function verifyEmail(token: string) {
    return post(`/auth/verify-email?token=${token}`, {})
        .then((response: any) => {
            history.push(paths.verifyEmail)
            return response
        })
}

