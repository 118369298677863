import { BehaviorSubject } from 'rxjs'
import { get, post, put, patch, del } from './http/httpMethods'
import Cookie from 'js-cookie'
import history from '../routes/history'
import { paths } from '../routes/routes.config'
import { showErrorToast } from '../utils/toastUtil'

let currentUserFromStorage: any

/*
 * Get current user from local storage
 */

currentUserFromStorage = localStorage.getItem('currentInterviewer')
currentUserFromStorage = JSON.parse(currentUserFromStorage)

const currentUserSubject = new BehaviorSubject(currentUserFromStorage || undefined)
const currentOrganizationSubject = new BehaviorSubject(
    (currentUserFromStorage &&
        currentUserFromStorage._org &&
        currentUserFromStorage._org[0]) ||
    undefined
)

/*
* Export as a Type
*/
export const interviewerService = {
    logout,
    authToken,
    loadCurrentUser,
    localLogout,
    sendOtpForInterviewer,
    verifyOtpForInterviewer,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentOrganization: currentOrganizationSubject.asObservable(),
    get currentOrganizationValue() {
        return currentOrganizationSubject.value
    },
}

/*
 * Logout method
 */
function logout() {
    localStorage.removeItem('currentInterviewer')
    Cookie.remove('_interviewerToken', { path: '/' })
    currentUserSubject.next({})
    window.location.href = '/interviewer-auth'
}


/*
 * Local logout, don't send API call
 */
function localLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentInterviewer')

    Cookie.remove('_interviewerToken', { path: '/' })

    currentUserSubject.next({})

    if (window.location.pathname !== '/interviewer-auth') {
        history.push('/interviewer-auth')
        window.location.reload()
    }
}

/*
 * Get auth token from cookie
 */
function authToken() {
    return Cookie.get('_interviewerToken')
}



/*
 * Fetch current user
 */
function loadCurrentUser() {
    return get(`/auth/self`).then((response: any) => {
        localStorage.setItem('currentInterviewer', JSON.stringify(response))

        currentUserSubject.next(response)
        currentOrganizationSubject.next(response._org[0])

        console.log(response);
        return response
    })
}



/*
 *sent Otp for interviewer login
 */
function sendOtpForInterviewer(payload: any) {
    return post(`/auth/interviewer/send-otp`, payload)
        .then((response: any) => {
            return response
        })
}

/*
*verify Otp for interviewer login
*/

function verifyOtpForInterviewer(payload: any) {
    return post('/auth/interviewer/verify-otp', payload)
        .then((response: any) => {
            if (!response.tfa) {
                handleLogin(response)
            }
            return response
        })

}
function handleLogin(response: any) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    Cookie.set('_interviewerToken', response.token, { path: '/' })

    localStorage.setItem('currentInterviewer', JSON.stringify(response.user))

    currentUserSubject.next(response.user)

    currentOrganizationSubject.next(response.user._org[0])

    if (response.user && !response.user._pre) {
        history.push(paths.interviewerDashboard)
    }
}