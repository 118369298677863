import React, { useContext } from 'react'
import logo from '../../assets/logo-whithout-tagline_white 1.png'
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap'
import { BoxArrowRight, CalendarDate, Gear } from 'react-bootstrap-icons'
import { Person, MenuButtonWide } from 'react-bootstrap-icons'
import { AiOutlineFolderOpen } from 'react-icons/ai'
import { NavLink, useLocation } from 'react-router-dom'
import { IoSearch } from 'react-icons/io5'
import { FaRegBell } from 'react-icons/fa'
import AvatarImg from '../../components/layout/header/AvatarImg'
import { UserContext } from '../../context/UserContext'
import { paths } from '../../routes/routes.config'
import history from '../../routes/history'
import { interviewerService } from '../../services/interviewer.service'

function InterviewerHeader(props: any) {
    const { user } = useContext(UserContext)

    type CustomToggleProps = {
        children?: React.ReactNode
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
    }
    const CustomToggle = React.forwardRef(
        (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
            <Nav.Link
                as={NavLink}
                to="#!"
                ref={ref}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.onClick) props.onClick(e)
                }}
            >
                <AvatarImg
                    className={'avatar'}
                    name={user?.name}
                    profilePicture={user?.profilePicture?.link}
                    round={true}
                    size="30px"
                />
            </Nav.Link>
        )
    )
    return (
        <>
            <Navbar variant="dark" fixed="top" className="navbar-style py-0">
                <Container fluid={true} className="h-100 Header">
                    <Navbar.Brand href="/" className="navbar-logo">
                        <img src={logo} alt="" height={35} className="mr-4" />
                    </Navbar.Brand>
                    <Nav className="h-100" activeKey={'location.pathname'}>
                        <Nav.Item className="h-100 mx-3">
                            <Nav.Link
                                as={NavLink}
                                className={`h-100 px-2 d-flex align-items-center }`}
                                to="/interviewer/dashboard"
                            >
                                <CalendarDate className="nav-icon-style" />
                                &nbsp; Schedule
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav className="ml-auto ">
                        <div className="d-flex align-items-center justify-content-around">
                            <li className="nav-item text-nowrap mr-3">
                                <IoSearch className="search-icon-style" />
                            </li>
                            <li className="mr-3">
                                <FaRegBell className="notification-icon-style" />
                            </li>
                            <li>
                                <Dropdown className="mr-3 mt-1">
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-custom-components"
                                    ></Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2 float-right dropdown-left-manual">
                                        <p
                                            className="ml-3 mb-2"
                                            style={{ color: '#000000' }}
                                        >
                                            Hello, {user.name}
                                        </p>
                                        <hr className='my-0 mx-2'/>

                                        <Dropdown.Item
                                            onClick={() =>
                                                history.push(
                                                    paths.interviewerUserProfile
                                                )
                                            }
                                        >
                                            <Person /> My Profile
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={() =>
                                                history.push(
                                                    paths.interviewerOrganization
                                                )
                                            }
                                        >
                                            <Gear /> Organization Settings
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                interviewerService.logout()
                                            }}
                                        >
                                            <BoxArrowRight /> Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </div>
                    </Nav>
                </Container>
            </Navbar>
            <Navbar className="navbar-style py-0 opacity-0">
                <Container className="h-100 Header"></Container>
            </Navbar>
            <br />
        </>
    )
}

export default InterviewerHeader
