import React from 'react'
import Routes from '../../routes/routes'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { paths, routes, interviewerRoutes } from '../../routes/routes.config'
import history from '../../routes/history'
import AuthLayout from '../../layouts/Auth/AuthLayout'
import AppLayout from '../../layouts/App/AppLayout'
import { authenticationService } from '../../services/auth.service'
import PageNotFound from '../../pages/auth/page-not-found/page-not-found'
import { interviewerService } from '../../services/interviewer.service'
import InterviewerLayout from '../../layouts/App/InterviewerLayout'
import InterviewerAuthLayout from '../../layouts/Auth/InterviewerAuthLayout'
import UserProvider from '../../context/UserContext'
export type AppNavigatorProps = any

export const AppNavigator = (props: AppNavigatorProps) => {
    return (
        <Router history={history}>
            <Switch>
                <Redirect
                    key="default"
                    exact
                    from="/"
                    to={interviewerService.currentUserValue ? paths.interviewerDashboard : paths.defaultPostLogin}
                ></Redirect>
                {console.log(interviewerService.currentUserValue)}

                {!interviewerService.currentUserValue && routes.map((item, index) => {
                    if (item.path.includes('auth/')) {
                        if (authenticationService.currentUserValue && (item.path !== '/auth/verify-email')) {
                            return (
                                <Redirect
                                    exact
                                    key={index}
                                    from={item.path}
                                    to={paths.defaultPostLogin}
                                ></Redirect>
                            )
                        }
                        else {
                            return (
                                <Route
                                    exact
                                    path={item.path}
                                    key={index}
                                    component={item.component}
                                >
                                    <AuthLayout key={index} />
                                </Route>
                            )
                        }
                    }
                    else if (item.path.includes('/interviewer-auth')) {
                        if (!authenticationService.currentUserValue) {
                            return (
                                <Route
                                    exact
                                    path={item.path}
                                    key={index}
                                    component={item.component}
                                >
                                    <InterviewerAuthLayout key={index} />{' '}
                                </Route>
                            )
                        }
                        else {
                            return (
                                <Redirect
                                    exact
                                    key={index}
                                    from={item.path}
                                    to={paths.defaultPostLogin}
                                ></Redirect>
                            )
                        }
                    }

                    else {
                        return (
                           
                            <Routes
                                exact
                                path={item.path}
                                key={index}
                                component={item.component}
                            >
                                 <UserProvider>
                                <AppLayout key={index} path={item.path} item={item} />{' '}
                                 </UserProvider>
                            </Routes>
                        )
                    }
                })}

                {/* interviewer routing */}
                {!authenticationService.currentUserValue && interviewerRoutes.map((item, index) => {
                    if (item.path.includes('auth/')) {
                        return (
                            <Redirect
                                exact
                                key={index}
                                from={item.path}
                                to={paths.interviewerDashboard}
                            ></Redirect>
                        )
                    }
                    else if (item.path.includes('/interviewer-auth')) {
                        if (interviewerService.currentUserValue) {
                            return (
                                <Redirect
                                    exact
                                    key={index}
                                    from={item.path}
                                    to={paths.interviewerDashboard}
                                ></Redirect>
                            )
                        }
                        else {
                            return (
                                <Route
                                    exact
                                    path={item.path}
                                    key={index}
                                    component={item.component}
                                >
                                    <InterviewerAuthLayout key={index} path={item.path} item={item} />{' '}
                                </Route>
                            )
                        }
                    }

                    else {
                        return (
                            <Routes
                                exact
                                path={item.path}
                                key={index}
                                component={item.component}
                            >
                                <InterviewerLayout key={index} path={item.path} item={item} />{' '}
                            </Routes>
                        )
                    }
                })}

                <Route component={PageNotFound} />
            </Switch>
        </Router>
    )
}