import React, { useEffect } from 'react'
import Avatar from 'react-avatar'
import './AvatarImg.scss'
import { baseURL } from '../../../services/constants/urls'
import classNames from 'classnames'


const AvatarImg = (props: any) => {
    return (
        <div>
            <Avatar
                name={props?.name}
                className={classNames(props?.className)}
                maxInitials={2}
                round={props?.round}
                size={props?.size}
                src={`${props?.profilePicture}`}
                
            />
        </div>
    )
}

export default AvatarImg;
